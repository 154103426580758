import React from 'react';
import { ApolloClient, createHttpLink, from, InMemoryCache, ApolloProvider } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

import { createUploadLink } from 'apollo-upload-client'

import { BrowserRouter, Routes, Route  } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { config } from './config';

import "react-multi-date-picker/styles/colors/green.css"

import './main.scss';

import Register from './pages/Register';
import Notfound from './pages/Notfound';
import VerifyEmail from './pages/VerifyEmail';

let httpLink = createUploadLink({
    uri: config.GRAPHQL_API_BASE_URL,
    //credentials: 'include'
})

/*
const httpLink = createHttpLink({
    uri: config.GRAPHQL_API_BASE_URL,
});
*/

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
            console.log(errorMessage);
            toast.error(config.DEBUG_ENABLED ? message : 'Ocorreu um erro');

            if(errorMessage.includes("Message: User is not allowed to use query: Viewer")) {
                //window.location = "/"
            }
        }
      );
    }
  
    if (networkError) {
        const errorMessage = `[Network error]: ${networkError}`;
        console.log(errorMessage);
        toast.error(config.DEBUG_ENABLED ? errorMessage : 'Ocorreu um erro');
        //window.location = "/logout"
    }
});

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache()
})

function App() {

    console.log('Company: ' + window.location.host.split('.')[0])

    return (
        <ApolloProvider client={client}>
            <BrowserRouter basename={'/'}>
                <ToastContainer
                position="bottom-right"
                hideProgressBar={true}
                />
                <Routes>
                    <Route exact path={`/`} element={<Register/>}/>
                    <Route exact path={`/verifyEmail`} element={<VerifyEmail/>}/>
                    <Route path='*' element={<Notfound/>}/>
                </Routes>
            </BrowserRouter>
        </ApolloProvider>
    );
}

export default App;