import { Fragment, useState } from "react";

import { gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

const UPSERT_MUTATION = gql`
    mutation Upsert($email: String, $name: String!, $companyName: String, $jobTitle: String, $fiscalNumber: String) {
        upsert_UserSignupRequest(userSignupRequest: { email: $email, name: $name, companyName: $companyName, jobTitle: $jobTitle, fiscalNumber: $fiscalNumber }) {
            id
        }
    }
`;

function Register() {

    const companyTenant = window.location.host.split('.')[0];
    const companyTenantUrl = `https://${companyTenant}.empathize.pt`

    const [isSignupComplete, setIsSignupComplete] = useState(false);

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);

    const upsert = async (formData) => {
        console.log(formData)
        
        const upsertResult = await upsertMutation({ 
            variables: {
                ...formData,
            },
        }).catch(e => {
            toast.error('Não foi possível efetuar o pedido. O email introduzido já existe.');
        });

        if(upsertResult?.data) {
            toast.success('Registo iniciado com sucesso!');
            reset()
            setIsSignupComplete(true);
        }
    }
    
    return (
        <Fragment> 
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0">
                    <div className="nav-top w-100">
                        <a href="/"><i className="icon-empathize text-success display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-xxl logo-text mb-0">empathize </span> </a>
        
                        <a href={companyTenantUrl} className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        {false && <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>}
                    </div>
                </div>


                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                    style={{backgroundImage: `url("/assets/images/bg/bg-register.jpg")`}}></div>
                    <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            { !isSignupComplete ? (
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-2">Cria a tua conta</h2>
                                <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-4 lh-32">Se não tiveres um endereço de email atribuído pela empresa, podes iniciar aqui o teu registo.</h6>
                                <form onSubmit={handleSubmit(upsert)}>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                        <input type="email" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" autoComplete="off" placeholder="Email" {...register("email", { required: true })} />
                                        {errors.email && <span className="text-red">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                        <input type="text" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" autoComplete="off" placeholder="Nome" {...register("name", { required: true })} />
                                        {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-ticket text-grey-500 pe-0"></i>
                                        <input type="text" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" autoComplete="off" placeholder="NIF" {...register("fiscalNumber")} />
                                        {errors.fiscalNumber && <span className="text-red">Verifique este campo</span>}
                                    </div>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-briefcase text-grey-500 pe-0"></i>
                                        <input type="text" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" autoComplete="off" placeholder="Empresa" {...register("companyName", { required: true })} />
                                        {errors.companyName && <span className="text-red">Este campo é obrigatório</span>}
                                    </div>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-id-badge text-grey-500 pe-0"></i>
                                        <input type="text" className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600" autoComplete="off" placeholder="Função" {...register("jobTitle", { required: true })} />
                                        {errors.jobTitle && <span className="text-red">Este campo é obrigatório</span>}
                                    </div>
                                    {false &&
                                    <div className="form-check text-left mb-3">
                                        <input type="checkbox" className="form-check-input mt-2" id="exampleCheck2" />
                                        <label className="form-check-label font-xsss text-grey-500">Accept Term and Conditions</label>
                                    </div>
                                    }
                                </form>
                                
                                <div className="col-sm-12 p-0 text-left">
                                    <div className="form-group mb-1"><a role="button" className="form-control text-center style2-input text-white fw-600 bg-success border-0 p-0 " onClick={handleSubmit(upsert)}>Registar</a></div>
                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Já tens uma conta? <a href={companyTenantUrl} className="fw-700 ms-1">Login</a></h6>
                                </div>
                                
                            </div>
                            ) : (
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-2">O teu pedido foi enviado</h2>
                                <h4 className="text-grey-500 font-xs fw-500 mt-0 mb-4 lh-32">Para confirmares o teu pedido, clica no link que enviámos para o teu endereço de email.</h4>
                            </div>
                            )}
                        </div> 
                    </div>
                    
                </div>
            </div>
        </Fragment>
    );
}

export default Register;