import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

const VERIFY_EMAIL_MUTATION = gql`
    mutation VerifyEmail($email: String!, $token: String!) {
        VerifyEmail(email: $email, token: $token)
    }
`;

const SuccessView = () => (
    <>
        <img src="/assets/images/bg/bg-success.png" className="w200 mb-4 ms-auto me-auto pt-md-5" />
        <h1 className="fw-700 text-grey-900 display3-size display4-md-size mb-4">O teu email foi verificado</h1>
        <p className="text-grey-500 font-xs mb-4">Quando o pedido de registo for aprovado, as credenciais de acesso serão enviadas para o teu email.</p>
    </>
)

const ErrorView = () => (
    <>
        <img src="/assets/images/bg/bg-43.png" className="w200 mb-4 ms-auto me-auto pt-md-5" />
        <h1 className="fw-700 text-grey-900 display3-size display4-md-size mb-4">Ups, não foi possível verificar o email</h1>
        <p className="text-grey-500 font-xs mb-4">O email pode já ter sido confirmado ou não existe qualquer pedido de registo pendente de verificação.</p>
    </>
)

function VerifyEmail() {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [statusComponent, setStatusComponent] = useState(null);

    const token = searchParams.get("token")
    const email = searchParams.get("email")

    const [verifyEmailMutation, { data, loading }] = useMutation(VERIFY_EMAIL_MUTATION);

    useEffect(() => {
        verifyEmail()
    }, [])

    const verifyEmail = async () => {
        if(!email || !token) {
            setStatusComponent(<ErrorView />)
            return;
        }
        const upsertResult = await verifyEmailMutation({ 
            variables: {
                token: token,
                email: email
            },
        }).catch(e => {
            setStatusComponent(<ErrorView />)
        });

        if(upsertResult?.data) {
            toast.success('Email verificado com sucesso!');
            setStatusComponent(<SuccessView />)
        } else {
            setStatusComponent(<ErrorView />)
        }
    }

    const companyTenant = window.location.host.split('.')[0];
    const companyTenantUrl = `https://${companyTenant}.empathize.pt`

    return (
        <Fragment> 
            <div className="main-content pt-0 bg-white ps-0 pe-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
                            <div className="card border-0 text-center d-block p-0">
                                {statusComponent}
                                <a href={companyTenantUrl} className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">Home Page</a>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>  
        </Fragment>
    );
}

export default VerifyEmail;