import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import 'moment/locale/pt'

import App from './App';
import * as serviceWorker from './serviceWorker';

// Moment
// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'pt';

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

moment.locale('pt')

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorker.register();